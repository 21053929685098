//* Mixins ==================================== *//

@mixin base-link-hover ($hover-color: $primary) {
	color: $hover-color;
	text-decoration: underline;
}

// Fonts: Link Styles
@mixin base-link($color: $link-color, $hover-color: $primary, $active-color: #363636, $focus-color: #363636, $focus-border-color: $link-focus-border-color, $font-size: 14px, $font-weight: $font-weight-normal) {
	color: $color;
	cursor: pointer;
	display: inline-block;
	text-decoration: underline;
	font: {
		size: $font-size;
		weight: $font-weight;
	};
	
	@media ( prefers-reduced-motion: no-preference ) {
		transition: $link-transition;
	}

	&:hover {
		@include base-link-hover($hover-color: $hover-color);
	}

	&:active {
		color: $active-color;
	}

	&:focus:not(:active):not(:hover) {
		border-radius: 2px;
		box-shadow: 0 0 0 1px $focus-border-color;
		color: $focus-color;
		outline: 0;
		transition: none;
	}
}

@mixin regular-link {
	@include base-link;
	position: relative;
}

@mixin regular-link-dark {
	@include base-link(
		$color: $white,
		$hover-color: $white,
		$active-color: $white,
		$focus-color: $white,
		$font-size: 13px,
		$font-weight: $font-weight-bold
	);

	&:hover,
	&:active {
		text-decoration: none;
	}
}

@mixin display-link-hover ($hover-color: $primary-hover) {
	color: $hover-color;
	text-decoration: underline;
}

@mixin display-link {
	@include base-link(
		$color: $primary,
		$hover-color: $primary-hover,
		$active-color: $primary-hover,
		$focus-color: $primary-hover,
		$font-size: 13px,
		$font-weight: $font-weight-bold
	);
}

@mixin display-link-dark {
	@include base-link(
		$color: $white,
		$hover-color: $white,
		$active-color: $white,
		$focus-color: $white,
		$font-size: 13px,
		$font-weight: $font-weight-bold
	);

	&:hover,
	&:active {
		text-decoration: none;
	}

	&:focus {
		text-decoration: underline;
	}
}

// Input Mixins
@mixin input-border-style {
	border: $input-border;
	border-radius: $input-radius;
	box-shadow: $input-shadow;
	outline: $input-outline;

	@media ( prefers-reduced-motion: no-preference ) {
		transition: $input-transition;
	}
}

@mixin input-style ($height) {
	@include input-border-style;
	appearance: none;
	background-clip: padding-box;
	background-color: $input-background-color;
	color: $input-color;
	display: inline-block;
	height: $height;
	line-height: $height;
	margin-bottom: $unit-xsmall;
	padding: 0 $unit;
	width: 100%;

	&:active,
	&:focus {
		border-color: $input-focus-color;
		outline: 0;
	}

	&:autofill,
	&:-webkit-autofill {
		border-color: $input-autofill-border-color;
		box-shadow: 0 0 0 $height $input-autofill-background-color inset;
	}
}

@mixin dropdown-style {
	@include input-border-style;
	appearance: none;
	background-color: $input-background-color;
	color: #575A5D;
	cursor: pointer;
	height: $input-height;
	padding: $unit-small 24px $unit-small $unit;
	width: 100%;

	/* stylelint-disable-next-line function-url-quotes */
	@-moz-document url-prefix() {
		text-indent: -2px;
	}

	&::-ms-expand {
		display: none;
	}

	&:focus {
		border-color: #585A5D;
		box-shadow: $input-shadow-active;
		outline: $input-outline;
	}

	&:autofill,
	&:-webkit-autofill {
		border-color: $input-autofill-border-color;
		box-shadow: 0 0 0 $input-height $input-autofill-background-color inset;
	}
}

@mixin share-breakpoints($map , $cssprop: content) {
	$description: "";

	@each $property, $value in $map {
		@if $description !=  "" {
			$description: $description + " | ";
		}
		$description: $description + "--" + $property + ": (max-width: " + inspect($value) + ")";
	}

	display: none;
	#{$cssprop}: $description;
}

//* Design System Font Treatments ==================================== *//

@mixin type-heading ($color: $black, $font-weight: $heading-font-weight) {
	color: $color;
	font-family: $font-family-heading;
	font-weight: $font-weight;
}

@mixin type-display-1-style ($line-height: 86px) {
	font-size: 76px;
	line-height: $line-height;
}

@mixin type-display-1 {
	@include type-heading();
	@include type-display-1-style();
}

@mixin type-display-2-style ($line-height: 71px) {
	font-size: $h1-size;
	line-height: $line-height;
}

@mixin type-display-2 {
	@include type-heading();
	@include type-display-2-style();
}

@mixin type-display-3-style ($line-height: 52px) {
	font-size: $h2-size;
	line-height: $line-height;
}

@mixin type-display-3 {
	@include type-heading();
	@include type-display-3-style();
}

@mixin type-title-1-style ($line-height: 1, $mobile-size: $h4-size, $tablet-size: 36px, $desktop-size: $h2-size) {
	font-size: $mobile-size;
	line-height: $line-height;

	@include media(">=medium") {
		font-size: $tablet-size;
	}

	@include media(">=large") {
		font-size: $desktop-size;
	}
}

@mixin type-title-1 {
	@include type-heading();
	@include type-title-1-style();
}

@mixin type-title-2-style ($line-height: 35px) {
	font-size: $h4-size;
	line-height: $line-height;
}

@mixin type-title-2 {
	@include type-heading();
	@include type-title-2-style();
}

@mixin type-title-3-style ($line-height: 29px) {
	font-size: $h5-size;
	line-height: $line-height;
}

@mixin type-title-3 {
	@include type-heading();
	@include type-title-3-style();
}

@mixin type-title-4-style ($line-height: 24px) {
	font-size: $h6-size;
	line-height: $line-height;
}

@mixin type-title-4 {
	@include type-heading();
	@include type-title-4-style();
}

@mixin type-subheading ($color: $main-text-color, $font-weight: $subheading-font-weight) {
	color: $color;
	font-weight: $font-weight;
}

@mixin type-subheading-large-style ($line-height: 28px) {
	font-size: 22px;
	line-height: $line-height;
}

@mixin type-subheading-large {
	@include type-subheading();
	@include type-subheading-large-style();
}

@mixin type-subheading-small-style ($line-height: 24px) {
	font-size: 18px;
	line-height: $line-height;
}

@mixin type-subheading-small {
	@include type-subheading();
	@include type-subheading-small-style();
}

@mixin type-subheading-xsmall-style ($line-height: 24px) {
	font-size: 16px;
	line-height: $line-height;
}

@mixin type-subheading-xsmall {
	@include type-subheading();
	@include type-subheading-xsmall-style();
}

@mixin type-paragraph ($color: $gray-5) {
	color: $color;
}

@mixin type-paragraph-large-style {
	font-size: 16px;
	line-height: 22px;
}

@mixin type-paragraph-large {
	@include type-paragraph();
	@include type-paragraph-large-style();
}

@mixin type-paragraph-small-style {
	font-size: 14px;
	line-height: 19px;
}

@mixin type-paragraph-small {
	@include type-paragraph();
	@include type-paragraph-small-style();
}

@mixin type-message-1-style {
	font-size: 12px;
	line-height: 16px;
}

@mixin type-message-1 {
	@include type-paragraph();
	@include type-message-1-style();
}

@mixin type-price($color: $price-color, $font-weight: $font-weight-medium) {
	color: $color;
	font-weight: $font-weight;
}

@mixin type-price-large-style {
	font-size: 18px;
	line-height: 24px;
}

@mixin type-price-large {
	@include type-price();
	@include type-price-large-style();
}

@mixin type-price-small-style {
	font-size: 16px;
	line-height: 22px;
}

@mixin type-price-small {
	@include type-price();
	@include type-price-small-style();
}

@mixin type-price-xsmall-style {
	font-size: 13px;
	line-height: 22px;
}

@mixin type-price-xsmall {
	@include type-price();
	@include type-price-xsmall-style();
}

@mixin type-call-to-action ($color: $main-text-color) {
	color: $color;
	font: {
		size: 13px;
		weight: $font-weight-medium;
	}
	line-height: 23px;
}

@mixin type-navigation ($color: $black) {
	color: $color;
	font: {
		size: 14px;
		weight: $font-weight-medium;
	}
	line-height: 19px;
}

@mixin type-captions ($color: $black, $text-transform: uppercase) {
	color: $color;
	font-weight: $font-weight-bold;
	letter-spacing: xd-letter-spacing-to-px(60);
	text-transform: $text-transform;
}

@mixin type-captions-large-style {
	font-size: 13px;
	line-height: 16px;
}

@mixin type-captions-large {
	@include type-captions();
	@include type-captions-large-style();
}

@mixin type-captions-small-style {
	font-size: 11px;
	line-height: 14px;
}

@mixin type-captions-small {
	@include type-captions();
	@include type-captions-small-style();
}

@mixin type-form-label {
	color: $black;
	font-family: $font-family-cta;
	font-size: 12px;
	font-weight: $font-weight-medium;
	line-height: 13px;
}

//* Image Functionality Mixins ==================================== *//

@mixin image-container-center($width: 100px, $height: 100px, $align-items: center, $justify-content: center, $margin: auto) {
	align-items: $align-items;
	display: flex;
	height: $height;
	justify-content: $justify-content;
	margin: $margin;
	width: $width;
}

//* Account Page Mixins ==================================== *//

@mixin account-link-hover {

	&:not(.g-display-link):not(.g-regular-link) {
		color: $primary;
	}
}

//* Product Card Mixins ==================================== *//

@mixin product-card-image-container($background-color: $transparent, $height: 0, $padding-bottom: 100%, $position: relative) {
	background-color: $background-color;
	height: $height;
	padding-bottom: $padding-bottom;
	position: $position;
}

@mixin product-card-image-position($left: 50%, $max-height: 100%, $position: absolute, $top: 50%, $transform: translate(-50%, -50%), $width: auto) {
	left: $left;
	max-height: $max-height;
	position: $position;
	top: $top;
	transform: $transform;
	width: $width;
}

//* Scrollbars ==================================== *//

@mixin scrollbar-vertical ($scrollbar-color: $gray-1, $scrollbar-border-radius: 2px, $scrollbar-width: 3px, $scrollbar-thumb-color: $gray-3) {

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
	}

	&::-webkit-scrollbar {
		border-radius: $scrollbar-border-radius;
		width: $scrollbar-width;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-thumb-color;
	}
}

@mixin scrollbar-horizontal ($scrollbar-color: #EAEAEA, $scrollbar-border-radius: 20px, $scrollbar-height: 6px, $scrollbar-thumb-color: $gray-3) {

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		border-radius: $scrollbar-border-radius;
	}

	&::-webkit-scrollbar {
		height: $scrollbar-height;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-thumb-color;
		border-radius: $scrollbar-border-radius;
	}
}
