@import "variables";

/* Not Found Page
	=========================================== */

.g-ntfd-image {
	margin-bottom: $unit;
}

.g-ntfd-image__img {
	width: 100%;
}

.g-ntfd-content {
	margin-bottom: $unit-xxlarge;
	text-align: center;
}

.g-ntfd-title {
	@include type-heading;
	font-size: 22px;
	line-height: $heading-line-height;
	margin-bottom: 10px;

	@include media( ">=large" ) {
		font-size: $h3-size;
		margin-bottom: 14px;
	}
}

.g-ntfd-text {
	margin-bottom: 38px;

	@include media( ">=large" ) {
		margin-bottom: 30px;
	}
}
